<template>
  <div class="card-product" v-if="product">     
    <div class="card-product-info" :class="{'price-bottom': !admin}">
      <a :href="'/product/'+product.id"><b-carousel v-if="imagesfromproduct"
        id="carousel-no-animation"
        :controls="imagesfromproduct.length > 1"
        :indicators="imagesfromproduct.length > 1"
        :interval="0"
      >
        <b-carousel-slide
          v-for="image in imagesfromproduct"
          :key="image.id"
          :img-src="'/'+image.path"
        ></b-carousel-slide>
      </b-carousel>
      <b-carousel v-else
        id="carousel-no-animation"
        :controls="false"
        :indicators="false"
        :interval="0"
      >
        <b-carousel-slide
          img-src="/img/no-photo.png"
        ></b-carousel-slide>
      </b-carousel>
    </a>
      <div class="m-3">
        <div class="card-name">
            <a :href="'/product/'+product.id"><b>{{ product.name }}</b></a>
        </div>
        <div class="card-description my-2 ql-editor  ql-viewer" v-html="product.description"></div>     
     
      <div class="card-price">
          <div style="line-height: 1.1;">
            <b>{{ pricefromproduct }} </b> <span class="ql-editor  ql-viewer ql-inline" v-html="settings.valutacatalog"></span>
          </div>  
          <div style="line-height: 1.1;"  v-if="priceRub">
            <b style="color:#aaa; font-size: 14px;">{{priceRub}} <b style="font-size: 10px;">РУБ</b></b> 
          </div>                
      </div>
      
      </div>
             
    </div>   
    <div class="card-product-buttons-admin" v-if="admin">    
      <b-row no-gutters>
        <b-col cols="6">
          <b-button @click="$router.push('/admin/product/'+product.id)" class="btn btn-secondary" block title="редактировать">
            <b-icon icon="pencil" aria-hidden="true"></b-icon>
          </b-button>          
        </b-col>
        <b-col cols="6">
          <b-button @click="deleteProduct()" class="btn btn-danger" block title="удалить">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>
        </b-col>
      </b-row>                                                 
    </div> 
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
export default {
  props: ["product", "admin", 'settings'],
  data() {
    return {
      
    };
  },
  computed:{
    priceRub(){
      let price = this.product.configurations.length==0?0:Math.min(...this.product.configurations.map(el=>el.price))
      return Authentication.priceRub(price,this.settings[this.settings.currency_type])
    },
    imagesfromproduct(){      
      if(this.product.colors.length>0){
      let images = this.product.colors.map(el=>el.images).flat()
      let images_main = images.filter(img=>img.main)
      if(images_main.length>0){
        return images_main
      }else{
        return [images[0]]
      }
      }else{
        return false
      }
       
    },
    pricefromproduct(){
      if(this.product.configurations.length==0){
        return 0
      }
      return  Math.min(...this.product.configurations.map(el=>el.price)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
  },
  methods: {    
    async deleteProduct(){
       let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/deleteproduct/"+this.product.id,
        "post"
      );      
      this.$emit('show-toast', data)
      this.$emit('updateList')
    },
  },
};
</script>
<style lang="scss">
.card-product {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding-bottom: 50px;
  padding-top: 20px;
  &-buttons{
      position: absolute;
      bottom: 0;
      right: 15px;
  }
  .card-name{
      font-size: 22px;
      a{
        color: $color3;
      }
  }
  .card-price{
      position: absolute;
      bottom: 10px;
      font-size: 22px;
      span{
          font-size: 10px;
          display:block;

      }
  }
  .btn,input{
      border-radius: 0;
      margin: 5px 0;
  }
  &-info { 
    position: relative;  
    height: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #fff;
  }
  .carousel-control-prev-icon {
 background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
@media(min-width: 768px){
.carousel-item{
  height: 115px;
  img{
    height: 100%;
    object-fit: cover;
  }
}
}
@media(min-width: 1200px){
.carousel-item{
  height: 140px;
  img{
    height: 100%;
    object-fit: cover;
  }
}
}

/*@media(max-width: 767px){
.carousel-item{
  height: 160px;
  img{
    height: 100%;
    object-fit: cover;
  }
}
}*/
}
.catalog-admin{
  .card-product {
    @media(min-width: 768px){
.carousel-item{
  height: 150px;
  img{
    height: 100%;
    object-fit: cover;
  }
}
}
@media(min-width: 1200px){
.carousel-item{
  height: 170px;
  img{
    height: 100%;
    object-fit: cover;
  }
}
}
  }
}
</style>